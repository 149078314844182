/* eslint-disable no-prototype-builtins */
import call from "../../../../service/http";
import bioConstants from "./bioConstants";

export default {
  state: {
    bio: [],
    candidateMaritalStatus: [],
    titles: [],
    sectors: [],
    countries: [],
    cities: [],
    counties: [],
  },
  mutations: {
    MUTATE: (state, payload) => {
      state[payload.state] = payload.value;
    },
  },
  getters: {
    bioGetters: (state) => (val) => state[val],
  },
  actions: {
    getBio: ({ commit }) => {
      commit("SET_LOADING", true, { root: true });
      call("get", bioConstants.bio)
        .then((res) => {
          commit("MUTATE", { state: "bio", value: res.data.data });
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          Event.$emit("ApiError", err.response.data.message);
        });
    },

    saveBio: ({ commit, dispatch }, data) => {
      commit("SET_LOADING", true, { root: true });
      delete data.lastModifiedDate;
      delete data.id;
      delete data.phoneNo;
      call("patch", bioConstants.bio, data)
        .then(() => {
          dispatch("getBio");
          // Event.$emit("goNextStep");
          dispatch("Profile/getProfile", null, { root: true });
          Event.$emit("ApiSuccess", "Successfully Saved Bio Data");
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          Event.$emit(
            "ApiError",
            err.response.data.message || err.message || err
          );
        });
    },

    updateBio: ({ commit, dispatch }, data) => {
      commit("SET_LOADING", true, { root: true });
      delete data.lastModifiedDate;
      delete data.id;
      delete data.phoneNo;
      call("post", bioConstants.update, data)
        .then(() => {
          dispatch("getBio");
          dispatch("Profile/getProfile", null, { root: true });
          dispatch("Auth/user", null, { root: true });
          Event.$emit("application-checklist");
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          Event.$emit("ApiError", err.response.data.message);
        });
    },
    getMaritalStatus: ({ commit }) => {
      commit("SET_LOADING", true, { root: true });
      call("get", bioConstants.marital)
        .then((res) => {
          commit("MUTATE", {
            state: "candidateMaritalStatus",
            value: res.data.data,
          });
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          Event.$emit("ApiError", err.response.data.message);
        });
    },
    getTitles: ({ commit }) => {
      commit("SET_LOADING", true, { root: true });
      call("get", bioConstants.titles)
        .then((res) => {
          commit("MUTATE", { state: "titles", value: res.data.data });
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          Event.$emit("ApiError", err.response.data.message);
        });
    },
    getSectors: ({ commit }) => {
      commit("SET_LOADING", true, { root: true });
      call("get", bioConstants.sectors)
        .then((res) => {
          commit("MUTATE", { state: "sectors", value: res.data.data });
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          Event.$emit("ApiError", err.response.data.message);
        });
    },

    getCountries: ({ commit }) => {
      commit("SET_LOADING", true, { root: true });
      call("get", bioConstants.countries)
        .then((res) => {
          commit("MUTATE", { state: "countries", value: res.data.data });
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          Event.$emit("ApiError", err.response.data.message);
        });
    },

    getCities: ({ commit }) => {
      commit("SET_LOADING", true, { root: true });
      call("get", bioConstants.cities)
        .then((res) => {
          commit("MUTATE", { state: "cities", value: res.data.data });
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          Event.$emit("ApiError", err.response.data.message);
        });
    },

    getCounties: ({ commit }) => {
      commit("SET_LOADING", true, { root: true });
      call("get", bioConstants.counties)
        .then((res) => {
          commit("MUTATE", { state: "counties", value: res.data.data });
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          Event.$emit("ApiError", err.response.data.message);
        });
    },
  },
};
