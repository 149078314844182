<template>
  <v-container fluid>
    <v-dialog v-model="showDialog" persistent max-width="500" class="my-6 mx-6">
      <v-card>
        <v-card-title
          >{{
            selectedAttachment && selectedAttachment.id ? "Update" : "Upload"
          }}
          {{ selectedAttachment.type }}</v-card-title
        >
        <v-card-text>
          <v-file-input
            label="Select file"
            outlined
            :value="selectedFile"
            @change="handleFilesChange"
            ref="resetControl"
            :loading="uploadBtnLoading"
          />
          <span
            style="
              color: red;
              display: block;
              margin-top: 1px;
              padding-inline: 8px;
            "
            v-if="fileUploadError !== ''"
          >
            {{ fileUploadError }}
          </span>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="showDialog = false" :disabled="uploadBtnLoading" >Close</v-btn>
          <v-btn
            color="primary"
            @click="uploadFiles"
            :disabled="fileUploadError !== '' || !selectedFile || uploadBtnLoading"
            :loading="uploadBtnLoading"
            >Upload</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
export default {
  name: "UploadDialog",
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
    selectedAttachment: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    selectedFile: {},
    fileUploadError: "",
    uploadBtnLoading: false,
  }),
  mounted() {
    this.$refs.resetControl.reset();
    this.selectedFile = {};
  },
  computed: {
    profile() {
      return this.$store.getters["Profile/profileGetters"]("profile");
    },
  },
  methods: {
    handleFilesChange(files) {
      this.fileUploadError = "";
      if (files.size > 10 * 1024 * 1024) {
        this.fileUploadError = "File size exceeds 10MB";
        return;
      }
      this.selectedFiles = files;
      const fileReader = new FileReader();
      fileReader.onload = () => {
        this.selectedFile.attachment = fileReader.result;
      };
      files && fileReader.readAsDataURL(files);
    },
    uploadFiles() {
      // Simulate file upload
      // Replace with actual file upload logic
      console.log("Upload started");
      this.uploadBtnLoading = true;
      const payload = {
        attachment: this.selectedFile.attachment.split(",")[1],
        documentCode: this.selectedAttachment.type,
        current: true,
        relatedRecordId: this.profile.id,
      };
      if (this.selectedAttachment.id) {
        payload.id = this.selectedAttachment.id;
        this.$store.dispatch(`Profile/updateAttachments`, payload);
      } else {
        this.$store.dispatch(`Profile/saveMandatoryAttachment`, payload);
      }
      setTimeout(() => {
        console.log("Upload completed");
        this.uploadBtnLoading = false;
        this.selectedFile = {};
        this.$refs.resetControl.reset();
        this.showDialog = false;
        this.$emit("setDialog");
      }, 1000);
    },
  },
};
</script>
